

<script>
import api from '@/services/secureHttps';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';

export default {


    mounted() {
        console.log("mounted organizationSelect", this.selectedOrganisationObj);
        if (typeof this.selectedOrganisationObj.id == 'undefined') {
            this.fetchOrganisationList();
        } else {
            this.setCurrentlySelectedOrg();
        }
    },

    computed: {
        ...mapGetters('context', [
            'selectedOrganisationObj',
            'userOrgArray'
        ]),
    },

    data() {
        return {
            selectedOrganisation: null,
            organisations: []
        };
    },

    methods: {
        ...mapActions('context', [
            'setSelectedOrganisation',
            'setUserOrgArray'
        ]),
       
        fetchOrganisationList() {
            console.log("created org comp");
            api.getOrganisations()
                .then(response => {

                    this.organisations = response.data.organisations;
                    this.setUserOrgArray({orgArray : response.data.organisations});
                    console.log('org data', response.data);
                    if (this.organisations.length > 0) {
                        this.setCurrentlySelectedOrg();
                    }
                });
        },

        onOrganisationSelected(el) {
            console.log("selected org: ", el);

            this.selectedOrganisation = el;
            this.setSelectedOrganisation({ organisation: el });
        },

        setCurrentlySelectedOrg() {
            if (typeof this.selectedOrganisationObj.id === 'undefined') {
                var defaultOrg = this.organisations.find(x => x.isDefaultOrganisation === true);
                console.log("setting default org", defaultOrg)
                this.onOrganisationSelected(defaultOrg);
                this.organisations = this.userOrgArray;
                console.log('refreshed org array', this.organisations);
                this.selectedOrganisation = defaultOrg;
            } else {
                this.selectedOrganisation = this.selectedOrganisationObj;
                console.log("userOrgArray", this.userOrgArray);
                this.organisations = this.userOrgArray;
            }

        }
    }
};
</script>
    
<template>
    <b-dropdown variant="primary" :text="selectedOrganisation === null ? 'Select organisation' : selectedOrganisation.name"
        id="dropdownMenuButton">
        <b-dropdown-item v-for="org in organisations" v-bind:key="org.id" v-on:click="onOrganisationSelected(org)">{{
            org.name
        }}</b-dropdown-item>
    </b-dropdown>
</template>
    
    