

<script>
import api from '@/services/secureHttps';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';

export default {
    mounted() {
        console.log("mounted websiteSelect", this.selectedWebsiteObj);
        if (typeof this.selectedWebsiteObj.id == 'undefined') {
            this.fetchWebsiteList();
        } else {
            this.selectedWebsite = this.selectedWebsiteObj;
            this.websites = this.userWebsiteArray;
        }
    },

    props: {
        organisationId: {
            type: Number
        }
    },

    data() {
        return {
            selectedWebsite: null,
            websites: []
        };
    },

    watch: {
        organisationId: {
            // eslint-disable-next-line no-unused-vars
            handler: function (x, y) {
                console.log("prop changed", x, y);
                this.fetchWebsiteList();
            }
        }
    },

    computed: {
        ...mapGetters('context', [
            'selectedWebsiteObj',
            'userWebsiteArray'
        ])
    },

    methods: {
        ...mapActions('context', [
            'setSelectedWebsite',
            'setUserWebsiteArray'
        ]),

        fetchWebsiteList() {
            console.log("created website comp");
            api.listUserWebistes(this.organisationId)
                .then(response => {
                    this.websites = response.data;
                    this.setUserWebsiteArray({ websiteArray: response.data })

                    if (this.websites.length > 0) {

                        this.setCurrentlySelectedWebsite();
                    }
                });
        },

        onWebsiteSelected(el) {
            console.log('onWebsiteSelected', el);
            this.selectedWebsite = el;
            this.setSelectedWebsite({ website: el });
        },

        setCurrentlySelectedWebsite() {
            if (typeof this.selectedOrganisationId === 'undefined' || typeof this.selectedOrganisationId.id === 'undefined') {
                console.log('setCurrentlySelectedWebsite', this.websites);
                this.onWebsiteSelected(this.websites[0]);
                this.selectedWebsite = this.websites[0];
            } else {
                this.selectedWebsite = this.selectedWebsiteObj;
                this.websites = this.userWebsiteArray;
                console.log('get this.userWebsiteArray ', this.userWebsiteArray);
            }
        }
    }
};
</script>

<template>
    <b-dropdown variant="primary" :text="selectedWebsite === null ? 'Select website' : selectedWebsite.websiteUrl"
        id="dropdownMenuButton">
        <b-dropdown-item v-for="website in websites" v-bind:key="website.id" v-on:click="onWebsiteSelected(website)">{{
            website.websiteUrl
        }}</b-dropdown-item>
    </b-dropdown>
</template>

