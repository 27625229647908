<script>
import {
  layoutComputed
} from "@/state/helpers";

export default {
  data() {
    return {
      settings: {
        minScrollbarLength: 60,
      },
    };
  },
  computed: {
    ...layoutComputed,
    layoutType: {
      get() {
        return "vertical";
      },
    },
  },

  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    },
  },

  mounted() {
    if (document.querySelectorAll(".navbar-nav .collapse")) {
      let collapses = document.querySelectorAll(".navbar-nav .collapse");
      collapses.forEach((collapse) => {
        // Hide sibling collapses on `show.bs.collapse`
        collapse.addEventListener("show.bs.collapse", (e) => {
          e.stopPropagation();
          let closestCollapse = collapse.parentElement.closest(".collapse");
          if (closestCollapse) {
            let siblingCollapses =
              closestCollapse.querySelectorAll(".collapse");
            siblingCollapses.forEach((siblingCollapse) => {
              if (siblingCollapse.classList.contains("show")) {
                let aChild = siblingCollapse.parentNode.firstChild;
                if (aChild.hasAttribute("aria-expanded")) {
                  aChild.setAttribute("aria-expanded", "false");
                }
                siblingCollapse.classList.remove("show");
              }
            });
          } else {
            let getSiblings = (elem) => {
              // Setup siblings array and get the first sibling
              let siblings = [];
              let sibling = elem.parentNode.firstChild;
              // Loop through each sibling and push to the array
              while (sibling) {
                if (sibling.nodeType === 1 && sibling !== elem) {
                  siblings.push(sibling);
                }
                sibling = sibling.nextSibling;
              }
              return siblings;
            };
            let siblings = getSiblings(collapse.parentElement);
            siblings.forEach((item) => {
              if (item.childNodes.length > 2)
                item.firstElementChild.setAttribute("aria-expanded", "false");
              let ids = item.querySelectorAll("*[id]");
              ids.forEach((item1) => {
                let aChild = item1.parentNode.firstChild;
                if (aChild.hasAttribute("aria-expanded")) {
                  aChild.setAttribute("aria-expanded", "false");
                }
                item1.classList.remove("show");
                if (item1.childNodes.length > 2) {
                  let val = item1.querySelectorAll("ul li a");

                  val.forEach((subitem) => {
                    if (subitem.hasAttribute("aria-expanded"))
                      subitem.setAttribute("aria-expanded", "false");
                  });
                }
              });
            });
          }
        });

        // Hide nested collapses on `hide.bs.collapse`
        collapse.addEventListener("hide.bs.collapse", (e) => {
          e.stopPropagation();
          let childCollapses = collapse.querySelectorAll(".collapse");
          childCollapses.forEach((childCollapse) => {
            let childCollapseInstance = childCollapse;
            childCollapseInstance.hide();
          });
        });
      });
    }
  },

  methods: {
    onRoutechange(ele) {
      this.initActiveMenu(ele.path);
      if (document.getElementsByClassName("mm-active").length > 0) {
        const currentPosition = document.getElementsByClassName("mm-active")[0].offsetTop;
        if (currentPosition > 500)
          if (this.$refs.isSimplebar)
            this.$refs.isSimplebar.value.getScrollElement().scrollTop = currentPosition + 300;
      }
    },

    initActiveMenu(ele) {
      setTimeout(() => {
        if (document.querySelector("#navbar-nav")) {
          let a = document.querySelector("#navbar-nav").querySelector('[href="' + ele + '"]');

          if (a) {
            a.classList.add("active");
            let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
            if (parentCollapseDiv) {
              parentCollapseDiv.classList.add("show");
              parentCollapseDiv.parentElement.children[0].classList.add("active");
              parentCollapseDiv.parentElement.children[0].setAttribute("aria-expanded", "true");
              if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
                parentCollapseDiv.parentElement.closest(".collapse").classList.add("show");
                if (parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling)
                  parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.classList.add(
                    "active");
              }
            }
          }
        }
      }, 0);
    },
  },
};
</script>

<template>
  <div class="container-fluid">
    <div id="two-column-menu"></div>

    <ul class="navbar-nav h-100" id="navbar-nav">
      <li class="menu-title">
        <span data-key="t-menu"> {{ $t("t-menu") }}</span>
      </li>

      <li class="nav-item">
        <a class="nav-link menu-link" href="#sidebarWebsite" data-bs-toggle="collapse" role="button" aria-expanded="false"
          aria-controls="sidebarWebsite">
          <i class="mdi mdi-web"></i>
          <span> Website</span>
        </a>
        <div class="collapse menu-dropdown" id="sidebarWebsite">
          <ul class="nav nav-sm flex-column">
            <li class="nav-item">
              <router-link to="/website/sitemap" class="nav-link custom-abc">
                Import Sitemap
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/website/webpage" class="nav-link custom-abc">
                Add Page
              </router-link>
            </li>
          </ul>
        </div>
      </li>
      <!-- end website menu -->

      <!-- start meta data menu-->
      <li class="nav-item">
        <a class="nav-link menu-link" href="#sidebarMetadata" data-bs-toggle="collapse" role="button"
          aria-expanded="false" aria-controls="sidebarMetadata">
          <i class="mdi mdi-code-json"></i>
          <span>Structured Data</span>
        </a>
        <div class="collapse menu-dropdown" id="sidebarMetadata">
          <ul class="nav nav-sm flex-column">
            <li class="nav-item">
              <router-link to="/metadata" class="nav-link custom-abc">
                Create/Edit
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/metadata/overview" class="nav-link custom-abc">
                Overview
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/metadata/autoExtract" class="nav-link custom-abc">
                Auto Extract
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/metadata/rules/websiteRules" class="nav-link custom-abc">
                Rules
              </router-link>
            </li>
          </ul>
        </div>
      </li>
      <!-- end meta data menu-->

       <!-- start page editing -->
       <li class="nav-item">
        <a class="nav-link menu-link" href="#sidebarEditor" data-bs-toggle="collapse" role="button" aria-expanded="false"
          aria-controls="sidebarEditor">
          <i class="mdi mdi-application-edit"></i>
          <span>Page Editor</span>
        </a>
        <div class="collapse menu-dropdown" id="sidebarEditor">
          <ul class="nav nav-sm flex-column">
            <li class="nav-item">
              <router-link  to="/choosePageToEdit" class="nav-link custom-abc">
                Edit Page
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/webPageEditor/viewDrafts" class="nav-link custom-abc">
                View Drafts
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/webPageEditor/allAmmendments/choosePage" class="nav-link custom-abc">
                All Ammendments
              </router-link>
            </li>
          </ul>
        </div>
      </li>
      <!-- end page editing -->

      <!-- moitor pages-->

            <li class="nav-item">
        <a class="nav-link menu-link" href="#monitorPages" data-bs-toggle="collapse" role="button" aria-expanded="false"
          aria-controls="monitorPages">
          <i class="mdi mdi-eye-outline"></i>
          <span>Monitor pages</span>
        </a>
        <div class="collapse menu-dropdown" id="monitorPages">
          <ul class="nav nav-sm flex-column">
            <li class="nav-item">
              <router-link to="/website/addtrackedpage" class="nav-link custom-abc">
                Add Page to Monitor
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/website/trackedpages" class="nav-link custom-abc">
                View Performance
              </router-link>
            </li>
          </ul>
        </div>
      </li>
      <!-- end monitor pages-->

      <!-- start analysis menu-->
      <li class="nav-item">
        <a class="nav-link menu-link" href="#sidebarAnalysis" data-bs-toggle="collapse" role="button"
          aria-expanded="false" aria-controls="sidebarAnalysis">
          <i class="mdi mdi-google-analytics"></i>
          <span>Analysis</span>
        </a>
        <div class="collapse menu-dropdown" id="sidebarAnalysis">
          <ul class="nav nav-sm flex-column">
            <li class="nav-item">
              <router-link to="/website/select-page-tech-seo" class="nav-link custom-abc">
                Audit Techical SEO
              </router-link>
            </li>
          </ul>
        </div>
      </li>
      <!-- end analysis menu-->

      <!-- start apps menu -->
      <li class="nav-item">
        <a class="nav-link menu-link" href="#sidebarApps" data-bs-toggle="collapse" role="button" aria-expanded="false"
          aria-controls="sidebarApps">
          <i class="mdi mdi-apps"></i>
          <span>Apps</span>
        </a>
        <div class="collapse menu-dropdown" id="sidebarApps">
          <ul class="nav nav-sm flex-column">
            <li class="nav-item">
              <router-link to="/apps/integrations" class="nav-link custom-abc">
                Integrations
              </router-link>
            </li>
            <!-- <li class="nav-item">
              <router-link to="/apps/installed" class="nav-link custom-abc">
                Installed Apps
              </router-link>
            </li> -->
          </ul>
        </div>
      </li>
      <!-- end apps menu -->

    </ul>
  </div>
  <!-- Sidebar -->
</template>